import AuthToken from './AuthToken.js';
import ProviderToken from './ProviderToken.js';
import store from '../store.js';

const props = () => {
  return {
    authToken: AuthToken,
    providerToken: ProviderToken,
    tokenUpdate: store.getState().tokenUpdate
  };
};

export default props;
