const messages = {
  en: {
    translation: {
      welcomeUser: 'Welcome',
      welcome: 'Welcome to the DATALOGUE portal',
      welcomeDeepIdeas: 'Welcome to the DeepIdeas portal',
      welcomeMobile: 'Welcome',
      Today: 'Today',
      Tomorrow: 'Tomorrow',
      myEvents: 'My appointments',
      loading: 'Loading...',
      close: 'Close',
      myRoles: 'Debug: My roles',
      name: 'Name',
      guiMenuDebug: 'Debug role membership',
      guiMenuAdminRoles: 'Manage roles',
      error: 'Error',
      settingsError: 'Sorry, an error has occurred. Please try again or contact the administrator.',
      ok: 'OK',
      loginHow: 'How would you like to log in?',
      loginM365: 'Login with Microsoft 365',
      imprint: 'Imprint',
      contact: 'Contact',
      conditions: 'Conditions',
      dataprivacy: 'Privacy policy',
      noEvents: 'no appointments',
      checkLogin: 'Validate login',
      portalLogin: 'To the portal login',
      copyToken: 'Copies the current auth-token to the clipboard',
      copyTokenDone: 'Token copies',
      featureNotSupported: 'Feature not supported by browser',
      errorGetRoles: 'Error while retrieving the rools',
      save: 'Save',
      incorrectInput: 'Incorrect input',
      successSave: 'Data successfully saved',
      errorSave: 'Error while saving the data',
      errorData: 'Cannot save: Incorrect entries',
      adminRoles: 'Manage permissions',
      roleName: 'Name',
      roleDescription: 'Description',
      maintenance: 'Maintenance',
      maintenanceText: 'Unfortunately, the DATALOGUE portal is currently in maintenance mode.',
      maintenanceTextDeepIdeas: 'Unfortunately, the DeepIdeas portal is currently in maintenance mode.',
      error_general: 'Unfortunately, an unexpected error occurred. Please try again.',
      error_authError: 'Unfortunately, there was an error during authentication. Please try again.',
      error_permission: 'You do not have the required rights to log in. Please contact your administrator.',
      guiMenuDebugRolesClear: 'Stop role debugging',
      errorGetUser: 'Failed to get users',
      errorSwitchUser: 'Error switching user',
      provider: 'Provider',
      groupId: 'Group-ID',
      newGroup: 'Create a new group',
      enterGroupId: 'Please enter the ID of the group',
      buttonCreate: 'Create',
      errorDataLoad: 'Error loading data',
      errorDataChange: 'Error changing data',
      providerChoose: 'Select provider',
      groupDescription: 'Group description',
      errorGetGroups: 'Error getting the groups',
      guiMenuCustomize: 'Customize the dashboard',
      guiMenuCustomizeEnd: 'Dashboard fixieren',
      guiMenuCustomizeLayout: 'Layout anpassen',
      selectLayout: 'Dashboard Layout auswählen',
      rows4colR: '4 Reihen, Spalte rechts (Standard)',
      rows4colL: '4 Reihen, Spalte links',
      rows4colRL: '4 Reihen, Spalte links und rechts',
      rows4: '4 Reihen',
      cols4: '4 Spalten',
      plain: 'Kein Raster',
      errorDashboardLoad: 'Fehler beim Laden des Dashboards',
      errorAppListLoad: 'Fehler beim Laden der App-Liste',
      loginGuest: 'Guest login',
      guiMenuShowCalender: 'Show calendar',
      guiMenuHideCalender: 'Hide calendar',
    }
  },
  de: {
    translation: {
      welcomeUser: 'Willkommen',
      welcome: 'Herzlich Willkommen im DATALOGUE Portal',
      welcomeDeepIdeas: 'Herzlich Willkommen im DeepIdeas Portal',
      welcomeMobile: 'Herzlich Willkommen',
      Today: 'Heute',
      Tomorrow: 'Morgen',
      myEvents: 'Meine Termine',
      loading: 'Wird geladen...',
      close: 'Schließen',
      myRoles: 'Debug: Meine Rollen',
      name: 'Name',
      guiMenuDebug: 'Debug Rollenzugehörigkeit',
      guiMenuAdminRoles: 'Berechtigungen verwalten',
      error: 'Fehler',
      settingsError: 'Leider ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut oder wenden Sie sich an den Administrator.',
      ok: 'OK',
      loginHow: 'Wie möchten Sie sich einloggen?',
      loginM365: 'Login mit Microsoft 365',
      imprint: 'Impressum',
      contact: 'Kontakt',
      conditions: 'AGB',
      dataprivacy: 'Datenschutz',
      noEvents: 'Keine Termine',
      checkLogin: 'Überprüfe Login',
      portalLogin: 'Zum Portal-Login',
      copyToken: 'Kopiert das aktuelle Auth-Token in die Zwischenablage',
      copyTokenDone: 'Token kopiert',
      featureNotSupported: 'Feature vom Browser nicht unterstützt',
      errorGetRoles: 'Fehler beim Abrufen der Rolen',
      save: 'Speichern',
      incorrectInput: 'Fehlerhafte Eingabe',
      successSave: 'Daten erfolgreich gespeichert',
      errorSave: 'Fehler beim Speichern der Daten',
      errorData: 'Speichern nicht möglich: Fehlerhafte Eingaben',
      adminRoles: 'Berechtigungen verwalten',
      roleName: 'Name',
      roleDescription: 'Beschreibung',
      consoleTokenDone: 'Token Payload in Console ausgegeben',
      consoleToken: 'Gibt den Token Payload in der Console aus',
      maintenance: 'Wartungsarbeiten',
      maintenanceText: 'Das DATALOGUE Portal befindet sich aktuell leider im Wartungsmodus.',
      maintenanceTextDeepIdeas: 'Das DeepIdeas Portal befindet sich aktuell leider im Wartungsmodus.',
      error_general: 'Leider ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es erneut.',
      error_authError: 'Leider ist bei der Authentifizierung ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
      error_permission: 'Sie verfügen nicht über die benötigten Rechte für die Anmeldung. Bitte wenden Sie sich an Ihren Administrator.',
      guiMenuDebugRolesClear: 'Rollendebugging beenden',
      errorGetUser: 'Fehler beim Abrufen der Benutzer',
      errorSwitchUser: 'Fehler beim Wechseln des Benutzers',
      provider: 'Provider',
      groupId: 'Gruppen-ID',
      newGroup: 'Neue Gruppe anlegen',
      enterGroupId: 'Bitte geben Sie die ID der Gruppe ein',
      buttonCreate: 'Anlegen',
      errorDataLoad: 'Fehler beim Laden der Daten',
      errorDataChange: 'Fehler beim Ändern der Daten',
      providerChoose: 'Provider wählen',
      groupDescription: 'Gruppen-Beschreibung',
      errorGetGroups: 'Fehler beim Abrufen der Gruppen',
      guiMenuCustomize: 'Dashboard anpassen',
      guiMenuCustomizeEnd: 'Dashboard fixieren',
      guiMenuCustomizeLayout: 'Layout anpassen',
      selectLayout: 'Dashboard Layout auswählen',
      rows4colR: '4 Reihen, Spalte rechts (Standard)',
      rows4colL: '4 Reihen, Spalte links',
      rows4colRL: '4 Reihen, Spalte links und rechts',
      rows4: '4 Reihen',
      cols4: '4 Spalten',
      plain: 'Kein Raster',
      errorDashboardLoad: 'Fehler beim Laden des Dashboards',
      errorAppListLoad: 'Fehler beim Laden der App-Liste',
      loginGuest: 'Gast-Login',
      guiMenuShowCalender: 'Kalender anzeigen',
      guiMenuHideCalender: 'Kalender ausblenden',
    }
  },
  fr: {
    translation: {
      welcomeUser: 'Bienvenue',
      welcome: 'Bienvenue sur le portail DATALOGUE',
      welcomeDeepIdeas: 'Bienvenue sur le portail DeepIdeas',
      welcomeMobile: 'Bienvenue',
      Today: 'Aujourd\'hui',
      Tomorrow: 'Demain',
      myEvents: 'Mes dates',
      loading: 'Chargement...',
      close: 'Conclure',
      myRoles: 'Debug: Mes rôles',
      name: 'Name',
      guiMenuDebug: 'Appartenance au rôles de débogage',
      guiMenuAdminRoles: 'Gérer les rôles',
      error: 'Erreur',
      settingsError: 'Désolé, une erreur s\'est produite. Veuillez réessayer ou contacter l\'administrateur.',
      ok: 'D\'accord',
      loginHow: 'Comment voulez-vous vous identifier?',
      loginM365: 'Connectez-vous avec Microsoft 365',
      imprint: 'Imprimer',
      contact: 'Contact',
      conditions: 'Conditions',
      dataprivacy: 'Protection des données',
      noEvents: 'pas de rendez-vous',
      checkLogin: 'Vérifier la connexion',
      portalLogin: 'À la connexion au portail',
      copyToken: 'Copie le auth-token actuel dans le presse-papiers',
      copyTokenDone: 'Copies de token',
      featureNotSupported: 'Fonctionnalité non prise en charge par le navigateur',
      errorGetRoles: 'Erreur lors de la récupération des rools',
      save: 'Sauvez',
      incorrectInput: 'Entrée incorrecte',
      successSave: 'Données sauvegardées avec succès',
      errorSave: 'Erreur lors de la sauvegarde des données',
      errorData: 'Sauvegarde impossible : Entrées incorrectes',
      adminRoles: 'Gérer les autorisations',
      roleName: 'Nom',
      roleDescription: 'Description',
      maintenance: 'Maintenance',
      maintenanceText: 'Malheureusement, le portail DATALOGUE est actuellement en mode maintenance.',
      maintenanceTextDeepIdeas: 'Malheureusement, le portail DeepIdeas est actuellement en mode maintenance.',
      error_general: 'Malheureusement, une erreur inattendue s\'est produite. Veuillez réessayer.',
      error_authError: 'Malheureusement, une erreur s\'est produite lors de l\'authentification. Veuillez réessayer.',
      error_permission: 'Vous ne disposez pas des droits nécessaires pour vous connecter. Veuillez vous adresser à votre administrateur.',
      guiMenuDebugRolesClear: 'Arrêter le débogage des rôles',
      errorGetUser: 'Échec de l\'obtention des utilisateurs',
      errorSwitchUser: 'Erreur lors du changement d\'utilisateur',
      provider: 'Fournisseuse',
      groupId: 'ID de groupe',
      newGroup: 'Créer un nouveau groupe',
      enterGroupId: 'Veuillez saisir l\'ID du groupe',
      buttonCreate: 'Créer',
      errorDataLoad: 'Erreur lors du chargement des données',
      errorDataChange: 'Erreur lors de la modification des données',
      providerChoose: 'Sélectionnez un fournisseur',
      groupDescription: 'Description du groupe',
      errorGetGroups: 'Erreur lors de l\'obtention des groupes',
      guiMenuCustomize: 'Personnalisez le tableau de bord',
      guiMenuCustomizeEnd: 'Dashboard fixieren',
      guiMenuCustomizeLayout: 'Layout anpassen',
      selectLayout: 'Dashboard Layout auswählen',
      rows4colR: '4 Reihen, Spalte rechts (Standard)',
      rows4colL: '4 Reihen, Spalte links',
      rows4colRL: '4 Reihen, Spalte links und rechts',
      rows4: '4 Reihen',
      cols4: '4 Spalten',
      plain: 'Kein Raster',
      errorDashboardLoad: 'Fehler beim Laden des Dashboards',
      errorAppListLoad: 'Fehler beim Laden der App-Liste',
      loginGuest: 'Connexion invité',
      guiMenuShowCalender: 'Afficher le calendrier',
      guiMenuHideCalender: 'Masquer le calendrier',
    }
  }
};

export default messages;
