const dispatch = {
  dispatch$setLang: (payload) => ({
    type: 'SET_LANG',
    payload: payload
  }),
  dispatch$setBranding: (payload) => ({
    type: 'SET_BRANDING',
    payload: payload
  }),
  dispatch$setFocus: (payload) => ({
    type: 'SET_FOCUS',
    payload: payload
  }),
};

export default dispatch;
