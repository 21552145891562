const globalStyles = {
  _portalBg: {
    // background: 'linear-gradient(-15deg, #FFCCAA, #AACCFF, #CCCCCC)'
    // background: 'linear-gradient(-15deg, #da5e00, #868686, #565656)'
    // background: 'linear-gradient(-15deg, #d9d9d9, #b2b2b2, #868686, #565656)', // alt DL
    background: 'linear-gradient(-15deg, #626c70, #565656)', // neu DL
  },
  '_portalBg.-deepideas': {
    background: 'linear-gradient(-15deg, #000000, #202020, #282828, #333333)'
  }
};

export default globalStyles;
