import React from 'react';

// Abstract
import withPortal from '../abstract/withPortal.jsx';

// Theme
import globalStyles from '../theme/globalStyles.js';

// Components
import LoadingBox from './LoadingBox.jsx';

const styles = () => ({...globalStyles, ...{
  loadingView: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  }
}});

class Loading extends React.Component {
  render() {
    const { classes, t } = this.props;

    return (
      <div className={`${this.props.noBg !== true ? classes._portalBg +  ' ' + (classes['_portalBg.-' + this.props.store$branding] ?? '') : ''} ${classes.loadingView}`}>
        <LoadingBox msg={t('loading')} />
      </div>
    );
  }
}

export default withPortal(Loading, {
  styles
});
