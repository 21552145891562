import React from 'react';
import {
  Redirect
} from 'react-router-dom';
import loadable from '@loadable/component';
import Loading from './../components/Loading.jsx';

import AuthToken from '../store/auth/AuthToken.js';

const routes = [
  {
    path: '/login/force',
    exact: true,
    component: () => {
      const Login = loadable(() => import(/* webpackChunkName: "login" */ './../views/Login.jsx'), {
        fallback: <Loading />
      });
      return <Login force={true} />
    }
  },
  {
    path: '/login',
    exact: true,
    component: loadable(() => import(/* webpackChunkName: "login" */ './../views/Login.jsx'), {
      fallback: <Loading />
    })
  },
  {
    path: '/authorization',
    exact: true,
    component: loadable(() => import(/* webpackChunkName: "auth" */ './../views/AuthorizationCode.jsx'), {
      fallback: <Loading />
    })
  },
  {
    path: '/dashboard',
    exact: true,
    component: ({location}) => {
      const Dashbord = loadable(() => import(/* webpackChunkName: "dashbord" */ './../views/Dashboard.jsx'), {
        fallback: <Loading />
      });
      return (
          AuthToken.isValid()
        ?
          <Dashbord />
        :
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location }
            }}
          />
      )
    }
  },
  {
    path: '/callback',
    exact: true,
    component: <div>Callback</div>
  },
  {
    path: '/error',
    component: loadable(() => import(/* webpackChunkName: "error" */ './../views/ErrorView.jsx'), {
      fallback: <Loading />
    })
  },
  {
    path: '/maintenance',
    exact: true,
    component: loadable(() => import(/* webpackChunkName: "maintenance" */ './../views/MaintenanceView.jsx'), {
      fallback: <Loading />
    })
  },
  {
    path: '*',
    component: ({location}) => {
      return <Redirect
        to={{
          pathname: '/dashboard',
          state: { from: location }
        }}
      />
    }
  }
];

export default routes;
