const actions = {

  UPDATE_TOKEN: (state, payload) => {
    state = {
      ...state,
      tokenUpdate: Date.now(),
      auth: {
        token: payload.authToken,
        refresh: payload.refreshToken
      },
      provider: {
        token: payload.providerToken,
        refresh: payload.providerRefreshToken
      }
    };
    return state;
  },

  SET_AUTH_TOKEN: (state, payload) => {
    state = {
      ...state,
      tokenUpdate: Date.now(),
      auth:
        {...state.auth, ...{token: payload}}
    };
    return state;
  },

  BACKUP_TOKEN: (state) => {
    state = {
      ...state,
      authBackup:
        {...state.auth}
    };
    return state;
  },

  RESTORE_TOKEN: (state) => {
    state = {
      ...state,
      tokenUpdate: Date.now(),
      auth:
        {...state.authBackup},
      authBackup: null
    };
    return state;
  },

  SET_AUTH_REFRESH_TOKEN: (state, payload) => {
    state = {
      ...state,
      tokenUpdate: Date.now(),
      auth:
        {...state.auth, ...{refresh: payload}}
    };
    return state;
  },

  SET_PROVIDER_TOKEN: (state, payload) => {
    state = {
      ...state,
      tokenUpdate: Date.now(),
      provider:
        {...state.provider, ...{token: payload}}
    };
    return state;
  },

  SET_PROVIDER_REFRESH_TOKEN: (state, payload) => {
    state = {
      ...state,
      tokenUpdate: Date.now(),
      provider:
        {...state.provider, ...{refresh: payload}}
    };
    return state;
  }

}

export default actions;
