import BaseToken from './BaseToken.js';
import store from '../store.js';

class AuthToken extends BaseToken {
  constructor() {
    super('auth');
  }

  getRoles() {
    let token = this.tokenData();
    return token.roles;
  }

  hasRole(role) {
    if (typeof role !== 'object') role = [role];
    let roles = this.getRoles();
    for (let x in role) {
      role[x] = role[x].toLowerCase();
      for (let i in roles) {
        if (roles[i].toLowerCase() === role[x]) {
          return true;
        }
      }
    }
    return false;
  }

  getProvider() {
    let tokenData = this.tokenData();
    return tokenData.provider || null;
  }

  isGuest() {
    let tokenData = this.tokenData();
    return tokenData.user.isGuest || false;
  }

  tokenUser() {
    let data = this.tokenData();
    return data.user;
  }

  backupToken() {
    store.dispatch({
      type: 'BACKUP_TOKEN'
    });
  }

  restoreToken() {
    if (store.getState().backupAuth !== null) {
      store.dispatch({
        type: 'RESTORE_TOKEN'
      });
    }
  }
}

export default new AuthToken();
