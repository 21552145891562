const props = (state) => {
  return {
    store$config: state.config,
    store$language: state.language,
    store$branding: state.branding,
    store$focus: state.focus,
  };
};

export default props;
