import initialState from './initialState.js';

import actionsAuth from './auth/actions.js';
import actionsPortal from './portal/actions.js';
import actionsGuis from './guis/actions.js';
import actionsDashboard from './dashboard/actions.js';

const actions = {...actionsAuth, ...actionsPortal, ...actionsGuis, ...actionsDashboard};

const reducer = (state = initialState, action) => {
  if (actions[action.type]) {
    state = actions[action.type](state, action.payload);
  }
  return state;
}

export default reducer;
