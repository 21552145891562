const actions = {

  GUI_OPEN: (state, payload) => {
    let guisOpen = [...state.guisOpen];
    let found = false;
    for (let i in guisOpen) {
      if (guisOpen[i] === payload) {
        found = true;
        break;
      }
    }
    if (!found) {
      guisOpen.push(payload);
    }
    state = {
      ...state,
      guisOpen: guisOpen.filter((el) => {
        return el != null;
      })
    };
    return state;
  },

  GUI_CLOSE: (state, payload) => {
    let guisClose = [];
    for (let i in state.guisOpen) {
      if (state.guisOpen[i] !== payload) {
        guisClose.push(state.guisOpen[i]);
      }
    }
    state = {
      ...state,
      guisOpen: guisClose.filter((el) => {
        return el != null;
      })
    };
    return state;
  },

  GUI_FOCUS: (state, payload) => {
    let guisOpen = [...state.guisOpen];
    let found = false;
    for (let i in guisOpen) {
      if (guisOpen[i] === payload) {
        found = i;
        break;
      }
    }
    if (found !== false) {
      guisOpen.splice(found, 1);
      guisOpen.push(payload);
    }
    state = {
      ...state,
      guisOpen: guisOpen.filter((el) => {
        return el != null;
      })
    };
    return state;
  }

}

export default actions;
