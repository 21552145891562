import React from 'react';
import ReactDOM from 'react-dom';
import './translations/i18n.js';
import DataloguePortal from './DataloguePortal.jsx';

// find branding
let branding = window.localStorage.getItem('__branding') || 'datalogue';
if (window.location.host.match(/deepideas\.digital/) !== null) {
  branding = 'deepideas';
}
if (!['datalogue', 'deepideas'].includes(branding)) {
  branding = 'datalogue';
}

function app(config) {
  ReactDOM.render(
    <DataloguePortal config={config} branding={branding} />,
    document.getElementById('app-portal')
  );
}

function init(config) {
  app(config);
}

export default init;
