import initialStateAuth from './auth/initialState.js';
import initialStatePortal from './portal/initialState.js';
import initialStateGuis from './guis/initialState.js';
import initialStateDashboard from './dashboard/initialState.js';

const initialState = {
  ...initialStateAuth,
  ...initialStatePortal,
  ...initialStateGuis,
  ...initialStateDashboard
};

export default initialState;
