const config = {
  apiDomain: {
    datalogue: 'https://portal.datalogue.de/api',
    deepideas: 'https://portal.deepideas.digital/api'
  },
  envSuffix: '',
  refresh: 5,
  debug: false
};

export default config;
