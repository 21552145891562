const STORE_NAME = 'portal-store';

const saveKeys = [
  'auth',
  'authBackup',
  'provider',
  'language',
  'guisOpen' // TODO: ggf wieder raus
];

export const loadState = (initialState) => {
  try {
    const serializedState = sessionStorage.getItem(STORE_NAME);

    if (serializedState === null) {
      return undefined;
    }

    let parsed = JSON.parse(serializedState);
    return {...initialState, ...parsed};
  } catch (error) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    let data = {...state};
    let keys = Object.keys(data);
    for (let i in keys) {
      if (!saveKeys.includes(keys[i])) {
        delete(data[keys[i]]);
      }
    }
    const serializedState = JSON.stringify(data);
    sessionStorage.setItem(STORE_NAME, serializedState);
  } catch (error) {
    // Ignore write errors.
  }
};
