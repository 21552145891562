import { createStore } from 'redux';
import { loadState, saveState } from './sessionStorage';
import throttle from 'lodash/throttle';

import reducer from './reducer.js';
import initialState from './initialState.js';

const isDev = (
  window.location.href.indexOf('docker') > -1 ||
  window.location.href.indexOf('local') > -1 ||
  window.location.href.indexOf('testcase') > -1
);

const persistedState = loadState(initialState);
const store = createStore(
  reducer,
  persistedState,
  (
    (isDev) ?
    (window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()) :
    undefined
  )
);

store.subscribe(throttle(() => {
  saveState(store.getState());
}, 1000));

export default store;
