// Theme
import { createMuiTheme } from '@material-ui/core/styles';

const themeStyles = {
  palette: {
    primary: {
      main: '#D95E00',
      light: '#FF8428',
      dark: '#8F4200',
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: '#565656',
      light: '#868686',
      dark: '#444444',
      contrastText: '#FFFFFF'
    }
  }
};

const theme = createMuiTheme(themeStyles);

export default theme;
