// Translations
import i18n from '../../translations/i18n.js';

const initialState = {
  config: {},
  language: i18n.language,
  branding: 'datalogue',
  focus: true
};

export default initialState;
