import jwt from 'jwt-decode';

// Store
import store from '../store.js';

class BaseToken {
  constructor(tokenKey) {
    this.tokenKey = tokenKey;
  }

  getToken() {
    let data = store.getState()[this.tokenKey] || null;
    if (data !== null) {
      return data.token || null;
    } else {
      return null;
    }
  }

  getRefreshToken() {
    let data = store.getState()[this.tokenKey] || null;
    if (data !== null) {
      return data.refresh || null;
    } else {
      return null;
    }
  }

  hasToken() {
    if (this.getToken() !== null) {
      return true;
    } else {
      return false;
    }
  }

  tokenData() {
    let token = this.getToken();
    if (token !== null) {
      return jwt(token);
    } else {
      return {};
    }
  }

  setToken(token) {
    store.dispatch({
      type: 'SET_' + this.tokenKey.toUpperCase() + '_TOKEN',
      payload: token
    });
  }

  setRefreshToken(token) {
    store.dispatch({
      type: 'SET_' + this.tokenKey.toUpperCase() + '_REFRESH_TOKEN',
      payload: token
    });
  }

  clear() {
    this.setToken(null);
    this.setRefreshToken(null);
  }

  expiresNearly() {
    if (this.hasToken()) {
      let tokenData = this.tokenData();
      let now = Math.floor((new Date()).getTime() / 1000);
      if (tokenData.exp > (now + (5 * 60))) { // Gültigkeit > 5 Min
        return false;
      }
    }
    return true;
  }

  isValid() {
    if (this.hasToken()) {
      let tokenData = this.tokenData();
      let now = Math.floor((new Date()).getTime() / 1000);
      if (tokenData.exp > now && (tokenData.nbf-60) <= now) {
        return true;
      }
    }
    return false;
  }
}

export default BaseToken;
