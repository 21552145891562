const actions = {

  UPDATE_PANELS: (state, payload) => {
    state = {
      ...state,
      panels: [...payload]
    };
    return state;
  },

  CHANGE_EDITMODE: (state, payload) => {
    state = {
      ...state,
      isEditMode: payload
    };
    return state;
  },

  CHANGE_PANELLAYOUT: (state, payload) => {
    state = {
      ...state,
      panelLayout: payload
    };
    return state;
  },

  CHANGE_APPCONFIG: (state, payload) => {
    state = {
      ...state,
      appConfig: payload
    };
    return state;
  },

  SET_CUSTOMIZING: (state, payload) => {
    state = {
      ...state,
      customizingShowCalender: payload.showCalender === true ? true : false
    };
    return state;
  }

};

export default actions;
