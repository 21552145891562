import React from 'react';

import { SnackbarProvider } from 'notistack';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';

// Theme
import './DataloguePortal.scss';
import { ThemeProvider } from '@material-ui/core/styles';
import themeDatalogue from './theme/datalogue/theme.js';
import themeDeepIdeas from './theme/deepideas/theme.js';

// Store
import store from './store/store.js';
import { Provider as StoreProvider } from 'react-redux';

// Config
import routes from './config/routes.js';

class DataloguePortal extends React.Component {
  constructor(props) {
    super(props);
    store.dispatch({
      type: 'ADD_CONFIG',
      payload: this.props.config
    });
    store.dispatch({
      type: 'SET_BRANDING',
      payload: this.props.branding
    });
  }

  render() {
    let theme = themeDatalogue;
    if (this.props.branding === 'deepideas') {
      theme = themeDeepIdeas;
    }
    return (
      <React.Fragment>
        <StoreProvider store={store}>
          <ThemeProvider theme={theme}>
            <SnackbarProvider maxSnack={10}>
              <CssBaseline />
              <Router>
                <Switch>
                  {routes.map((route, i) => {
                    return (
                      <Route
                        key={i}
                        path={route.path}
                        render={props => (
                          <route.component {...props} routes={route.routes} />
                        )}
                      />
                    );
                  })}
                </Switch>
              </Router>
            </SnackbarProvider>
          </ThemeProvider>
        </StoreProvider>
      </React.Fragment>
    );
  }
};

export default DataloguePortal;
