const dispatch = {
  dispatch$guiOpen: (payload) => ({
    type: 'GUI_OPEN',
    payload: payload
  }),
  dispatch$guiClose: (payload) => ({
    type: 'GUI_CLOSE',
    payload: payload
  }),
  dispatch$guiFocus: (payload) => ({
    type: 'GUI_FOCUS',
    payload: payload
  })
};

export default dispatch;
