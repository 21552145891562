const actions = {

  ADD_CONFIG: (state, payload) => {
    state = {
      ...state,
      config: payload
    };
    return state;
  },

  SET_LANG: (state, payload) => {
    state = {
      ...state,
      language: payload
    };
    return state;
  },

  SET_BRANDING: (state, payload) => {
    state = {
      ...state,
      branding: payload
    };
    return state;
  },

  SET_FOCUS: (state, payload) => {
    state = {
      ...state,
      focus: payload
    };
    return state;
  },

};

export default actions;
