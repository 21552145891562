import propsAuth from './auth/mapStoreToProps.js';
import propsDashboard from './dashboard/mapStoreToProps.js';
import propsPortal from './portal/mapStoreToProps.js';
import propsGuis from './guis/mapStoreToProps.js';

const mapStoreToProps = (state) => {
  return {
    ...propsAuth(state),
    ...propsDashboard(state),
    ...propsPortal(state),
    ...propsGuis(state)
  };
};

export default mapStoreToProps;
